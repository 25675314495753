export default [
  {
    header: 'Accounts Info',
  },
  {
    title: 'Accounts',
    route: 'account-list',
    icon: 'GridIcon',
  },
  {
    title: 'Acco. Transactions',
    route: 'account-transaction',
    icon: 'SearchIcon',
  },
];
