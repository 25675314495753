export default [
  {
    header: 'Statistics',
  },
  {
    title: 'Balances',
    route: 'statistic-balance',
    icon: 'DollarSignIcon',
  },
  {
    title: 'Standing Orders',
    route: 'statistic-standing-order',
    icon: 'PocketIcon',
  },
];
