export default [
  {
    header: 'Posts',
  },
  {
    title: 'Find Posts',
    route: 'post-list',
    icon: 'SearchIcon',
  },
  {
    title: 'Add Post',
    route: 'post-add',
    icon: 'FilePlusIcon',
  },
];
