export default [
  {
    header: 'Taxi Information',
  },
  {
    title: 'Taxis',
    route: 'taxi-list',
    icon: 'TruckIcon',
  },
  {
    title: 'Taxis Transactions',
    route: 'taxi-transaction',
    icon: 'SearchIcon',
  },
];
