<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <dark-Toggler class="d-none d-lg-block" />
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template
          #button-content
          v-if="userData"
        >
          <div class="d-flex user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ userData.username }}
            </p>
            <span class="user-status">User</span>
          </div>
          <b-avatar
            size="40"
            :text="getInitials"
            variant="light-primary"
          />
        </template>

        <b-dropdown-item
          :to="{ name: 'user-profile' }"
          link-class="d-flex align-items-center"
        >
          <feather-icon
            size="16"
            icon="UserIcon"
            class="mr-50"
          />
          <span>Profile</span>
        </b-dropdown-item>

        <b-dropdown-divider />

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="logout"
        >
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span>Logout</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar,
} from 'bootstrap-vue';
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue';
import store from '@/store';
import router from '@/router';
import { computed } from '@vue/composition-api';
import useUtils from '@/composables/useUtils';

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,

    // Navbar Components
    DarkToggler,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => { },
    },
  },
  setup() {
    const { generateInitials } = useUtils();

    const userData = computed(() => store.getters['authentication/authenticatedUser']);
    const getInitials = computed(() => {
      const firstName = userData.value.firstName;
      const lastName = userData.value.lastName;

      if (!firstName || !lastName) return '';

      return generateInitials(firstName, lastName);
    });

    return {
      userData,
      getInitials,
      logout: async () => {
        await store.dispatch('authentication/signOut');
        router.push({ name: 'authentication-login' });
      }
    };
  }
};
</script>
