export default [
  {
    header: 'Companies Information',
  },
  {
    title: 'Companies',
    route: 'company-list',
    icon: 'BriefcaseIcon',
  },
  {
    title: 'Comp. Transactions',
    route: 'companies-transaction',
    icon: 'SearchIcon',
  },
];
