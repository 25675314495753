export default [
  {
    header: 'Internal',
  },
  {
    title: 'Staff',
    route: 'staff-list',
    icon: 'UsersIcon',
  },
  {
    title: 'S.O. Types',
    route: 'standing-order-type-list',
    icon: 'SettingsIcon',
  },
  {
    title: 'Rates',
    icon: 'DollarSignIcon',
    children: [
      {
        title: 'Crew',
        route: 'rate-crew',
      },
      {
        title: 'Gib Air',
        route: 'rate-gib-air',
      },
      {
        title: 'Wait Time',
        route: 'rate-wait-time',
      },
    ],
  },
  {
    title: 'Point of Interest',
    route: 'poi-list',
    icon: 'MapPinIcon',
  },
  {
    title: 'Settings',
    route: 'setting-form',
    icon: 'SettingsIcon',
  },
];
