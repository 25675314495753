export default [
  {
    header: 'Bookings',
  },
  {
    title: 'Find Booking',
    route: 'booking-list',
    icon: 'SearchIcon',
  },
  {
    title: 'Add Booking',
    icon: 'FilePlusIcon',
    children: [
      {
        title: 'Crew',
        route: 'booking-crew',
      },
      {
        title: 'Pax',
        route: 'booking-pax',
      },
      {
        title: 'GHA',
        route: 'booking-gha',
      },
      {
        title: 'Airport Pick Up',
        route: 'booking-airport-pick-up',
      },
      {
        title: 'Gib Air',
        route: 'booking-gib-air',
      },
      {
        title: 'Cancelled',
        route: 'booking-cancelled',
      },
    ],
  },
  {
    title: 'Booking Settings',
    route: 'booking-settings',
    icon: 'SettingsIcon',
  },
];
