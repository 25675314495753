import account from './account';
import booking from './booking';
import companies from './company';
import dashboard from './dashboard';
import frontierTour from './frontier-tour';
import internal from './internal';
import post from './post';
import statistic from './statistic';
import taxi from './taxi';

export default [
  ...dashboard,
  ...booking,
  ...frontierTour,
  ...post,
  ...companies,
  ...taxi,
  ...account,
  ...statistic,
  ...internal,
];
