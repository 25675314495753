export default [
  {
    header: 'Frontier Tours',
  },
  {
    title: 'Find Tours',
    route: 'frontier-tour-list',
    icon: 'SearchIcon',
  },
  {
    title: 'Add Tour',
    route: 'frontier-tour-add',
    icon: 'FilePlusIcon',
  },
  {
    title: 'Add Mult. Tour',
    route: 'frontier-tour-add-multiple',
    icon: 'PlusSquareIcon',
  },
  {
    title: 'Tour Settings',
    route: 'frontier-tour-settings',
    icon: 'SettingsIcon',
  },
];
